import { j as i } from "./jsx-runtime-D0AQ1Mn7.js";
import d from "styled-components";
import g from "clsx";
import { I as $ } from "./Icon-CIVajG79.js";
const t = {
  sm: 32,
  md: 40,
  lg: 48,
  xl: 56
}, o = {
  sm: 10,
  md: 10,
  lg: 12,
  xl: 14
}, f = d.div`
  display: flex;
  
  background-color: transparent;
  
  box-sizing: border-box;
  
  svg:first-child {
    position: absolute;
  }
  
  &.size-sm {
    width: ${t.sm}px;
    height: ${t.sm}px;
    padding: ${o.sm}px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &.size-md {
    width: ${t.md}px;
    height: ${t.md}px;
    padding: ${o.md}px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &.size-lg {
    width: ${t.lg}px;
    height: ${t.lg}px;
    padding: ${o.lg}px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &.size-xl {
    width: ${t.xl}px;
    height: ${t.xl}px;
    padding: ${o.xl}px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  
  &.color-primary {
    svg:first-child circle {
      stroke: ${(e) => e.theme.palette.primary[600].hex};
    }

    color: ${(e) => e.theme.palette.primary[600].hex};
  }

  &.color-grey {
    svg:first-child circle {
      stroke: ${(e) => e.theme.palette.grays[600].hex};
    }
    
    color: ${(e) => e.theme.palette.grays[600].hex};
  }

  &.color-success {
    svg:first-child circle {
      stroke: ${(e) => e.theme.palette.success[600].hex};
    }
    
    color: ${(e) => e.theme.palette.success[600].hex};
  }
  
  &.color-warning {
    svg:first-child circle {
      stroke: ${(e) => e.theme.palette.warning[600].hex};
    }
    
    color: ${(e) => e.theme.palette.warning[700].hex};
  }
  
  &.color-error {
    svg:first-child circle {
      stroke: ${(e) => e.theme.palette.error[600].hex};
    }

    color: ${(e) => e.theme.palette.error[700].hex};
  }
`, j = (e) => {
  const {
    size: c = "md",
    color: n,
    className: h,
    ...x
  } = e, a = g(
    `size-${c}`,
    `color-${n}`,
    h
  ), l = o[c], r = t[c], s = r / 2, m = s - Number(l) / 4 + 1, p = s - 3 * l / 4 + 1;
  return /* @__PURE__ */ i.jsxs(f, { className: a, children: [
    /* @__PURE__ */ i.jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: r, height: r, viewBox: `0 0 ${r} ${r}`, fill: "none", children: [
      /* @__PURE__ */ i.jsx("circle", { cx: s, cy: s, r: m, stroke: "black", strokeWidth: 1.66667, opacity: "0.1" }),
      /* @__PURE__ */ i.jsx("circle", { cx: s, cy: s, r: p, stroke: "black", strokeWidth: 1.66667, opacity: "0.3" })
    ] }),
    /* @__PURE__ */ i.jsx($, { ...x })
  ] });
};
export {
  j as O
};
