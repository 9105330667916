import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { forwardRef as y } from "react";
import { F as A } from "./FieldLayout-Bcp8z6bT.js";
import { I as S } from "./Input-WbeB6EhF.js";
import { F as T } from "./FieldSupportingText-DM_pKcm9.js";
import { F as w } from "./FieldLabel-DQbQd09-.js";
const C = y((s, i) => {
  const {
    id: r,
    as: d = "input",
    type: l = "text",
    value: p,
    error: t = !1,
    disabled: o = !1,
    required: m = !1,
    label: a,
    placeholder: u,
    supportingText: n,
    withStartAdornmentSeparator: f,
    withEndAdornmentSeparator: x,
    startAdornment: F,
    endAdornment: c,
    Layout: b = A,
    ...h
  } = s, j = a ? /* @__PURE__ */ e.jsx(
    w,
    {
      id: r,
      label: a,
      required: m
    }
  ) : void 0, I = /* @__PURE__ */ e.jsx(
    S,
    {
      id: r,
      as: d,
      ref: i,
      type: l,
      error: t,
      placeholder: u,
      disabled: o,
      value: p,
      withStartAdornmentSeparator: f,
      withEndAdornmentSeparator: x,
      startAdornment: F,
      endAdornment: c,
      ...h
    }
  ), g = n ? /* @__PURE__ */ e.jsx(
    T,
    {
      supportingText: n,
      error: t
    }
  ) : void 0;
  return /* @__PURE__ */ e.jsx(
    b,
    {
      className: `${t ? "error" : ""} ${o ? "disabled" : ""}`,
      label: j,
      element: I,
      supportingText: g
    }
  );
});
C.displayName = "InputField";
export {
  C as I
};
