import { j as m } from "./jsx-runtime-D0AQ1Mn7.js";
import l from "styled-components";
import { T as d } from "./Typography-BKaAEmpq.js";
import "./components/Typography/styles.es.js";
const n = l(d)`
  &.required:after {
    content: "*";
  }
`, x = (e) => {
  const {
    size: r = "sm",
    id: t,
    label: o,
    required: s,
    className: a = "",
    ...i
  } = e;
  return /* @__PURE__ */ m.jsx(
    n,
    {
      ...i,
      htmlFor: t,
      size: r,
      variant: "text",
      weight: "medium",
      forwardedAs: "label",
      className: `${s ? "required" : ""} ${a}`,
      children: o
    }
  );
};
export {
  x as F
};
