import { j as r } from "./jsx-runtime-D0AQ1Mn7.js";
import { forwardRef as v } from "react";
import m from "clsx";
import p from "styled-components";
import "./Typography-BKaAEmpq.js";
import { TypographyCSS as l } from "./components/Typography/styles.es.js";
const S = p.div`
  ${l.text.md}

  display: inline-flex;
  flex-direction: row;
  
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  
  border-radius: 8px;

  background: ${(e) => e.theme.palette.white.hex};

  color: ${(e) => e.theme.palette.grays[600].hex};

  &.disabled {
    color: ${(e) => e.theme.palette.grays[500].hex};
    border-color: ${(e) => e.theme.palette.grays[300].hex};
    background: ${(e) => e.theme.palette.grays[50].hex};
  }

  > .start-adornment {
    padding: 0 12px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: ${(e) => e.theme.palette.grays[300].hex};
  }

  > .end-adornment {
    padding: 0 12px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: ${(e) => e.theme.palette.grays[300].hex};
  }
    
  .start-adornment, .end-adornment {
    display: flex;
    flex-flow: row;

    color: ${(e) => e.theme.palette.grays[500].hex};
    
    align-items: center;

    gap: 8px;
    
    svg {
      max-width: 20px;
      max-height: 20px;
    }
  }
`, A = p.div`
  flex: 1;
  
  font-style: inherit;
  
  display: inline-flex;
  flex-flow: row;
  
  border-width: 1px;
  border-style: solid;
  border-color: ${(e) => e.theme.palette.grays[300].hex};
  
  > input, > textarea {
    padding: 10px 14px;
  }
  
  &.soft-start {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &.hard-start {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.soft-end {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.hard-end {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.error {
    border-color: ${(e) => e.theme.palette.error[300].hex};
  }

  &:focus-within {
    border-color: ${(e) => e.theme.palette.primary[300].hex};
    box-shadow: 0 0 0 4px ${(e) => e.theme.palette.primary[100].hex}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    &.error {
      border-color: ${(e) => e.theme.palette.error[300].hex};
      box-shadow: 0 0 0 4px ${(e) => e.theme.palette.error[100].hex}, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  > .start-adornment {
    margin-left: 12px;
  }

  > .end-adornment {
    margin-right: 12px;
  }
`, C = p.input`
  flex: 1;

  font-family: inherit;

  ${l.text.md}
  ${l.regular}

  color: ${(e) => e.theme.palette.grays[900].hex};
  
  border: none;
  border-radius: 8px;
  outline: none;
  
  &:not(:first-child) {
    padding-left: 8px;
  }

  &:not(:last-child) {
    padding-right: 8px;
  }

  &:disabled {
    color: ${(e) => e.theme.palette.grays[500].hex};
    border-color: ${(e) => e.theme.palette.grays[300].hex};
    background: ${(e) => e.theme.palette.grays[50].hex};
  }
`, I = v((e, b) => {
  const {
    as: c = "input",
    type: g = "text",
    value: f,
    pattern: u,
    placeholder: y,
    error: n = !1,
    disabled: a = !1,
    readOnly: $ = !1,
    withStartAdornmentSeparator: t = !1,
    withEndAdornmentSeparator: o = !1,
    startAdornment: d,
    endAdornment: s,
    className: w,
    ...N
  } = e, i = m({
    disabled: a,
    error: n
  }, w), j = m({
    disabled: a,
    error: n,
    "soft-start": !t,
    "hard-start": t,
    "soft-end": !o,
    "hard-end": o
  }), x = d && /* @__PURE__ */ r.jsx("div", { className: "start-adornment", children: d }), h = s && /* @__PURE__ */ r.jsx("div", { className: "end-adornment", children: s });
  return /* @__PURE__ */ r.jsxs(S, { className: i, children: [
    t && x,
    /* @__PURE__ */ r.jsxs(A, { className: j, children: [
      !t && x,
      /* @__PURE__ */ r.jsx(
        C,
        {
          ...N,
          ref: b,
          as: c,
          type: g,
          disabled: a,
          readOnly: $,
          value: f,
          pattern: u,
          placeholder: y,
          className: i
        }
      ),
      !o && h
    ] }),
    o && h
  ] });
});
I.displayName = "Input";
export {
  I
};
