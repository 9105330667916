import { j as r } from "./jsx-runtime-D0AQ1Mn7.js";
import p from "styled-components";
import x from "clsx";
import { I as d } from "./Icon-CIVajG79.js";
const m = {
  sm: 32,
  md: 40,
  lg: 48,
  xl: 56
}, u = {
  sm: 10,
  md: 10,
  lg: 12,
  xl: 14
}, $ = p.div`
  display: inline-flex;

  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  color: ${(e) => e.theme.palette.grays[700].hex};
  background-color: ${(e) => e.theme.palette.white.hex};
  
  box-sizing: border-box;
    
  position: relative;


  svg:first-child {
    circle {
      stroke: none;
    }
    position: absolute;
  }
    
  svg:last-child {
    z-index: 10;
  }
  
  &.size-sm {
    width: 32px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &.size-md {
    width: 40px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &.size-lg {
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &.size-xl {
    width: 56px;
    height: 56px;
    padding: 14px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
    
  &.color-primary {
    background-color: ${(e) => e.theme.palette.primary[100].hex};
    &.variant-light-circle-outline {
      background-color: ${(e) => e.theme.palette.primary[50].hex};
    }
      
    svg:first-child circle {
      fill: ${(e) => e.theme.palette.primary[100].hex};
    }
  
    color: ${(e) => e.theme.palette.primary[600].hex};
  }
  
  &.color-gray {
    background-color: ${(e) => e.theme.palette.grays[100].hex};
    &.variant-light-circle-outline {
      background-color: ${(e) => e.theme.palette.grays[50].hex};
    }
      
    svg:first-child circle {
      fill: ${(e) => e.theme.palette.grays[100].hex};
    }
    
    color: ${(e) => e.theme.palette.grays[600].hex};
  }
  
  &.color-success {
    background-color: ${(e) => e.theme.palette.success[100].hex};
    &.variant-light-circle-outline {
      background-color: ${(e) => e.theme.palette.success[50].hex};
    }
      
    svg:first-child circle {
      fill: ${(e) => e.theme.palette.success[100].hex};
    }
    
    color: ${(e) => e.theme.palette.success[600].hex};
  }
  
  &.color-warning {
    background-color: ${(e) => e.theme.palette.warning[100].hex};
    &.variant-light-circle-outline {
      background-color: ${(e) => e.theme.palette.warning[50].hex};
    }
      
    svg:first-child circle {
      fill: ${(e) => e.theme.palette.warning[100].hex};
    }
    
    color: ${(e) => e.theme.palette.warning[700].hex};
  }
  
  &.color-error {
    background-color: ${(e) => e.theme.palette.error[100].hex};
    &.variant-light-circle-outline {
      background-color: ${(e) => e.theme.palette.error[50].hex};
    }
      
    svg:first-child circle {
      fill: ${(e) => e.theme.palette.error[100].hex};
    }
  
    color: ${(e) => e.theme.palette.error[700].hex};
  }
`, k = (e) => {
  const {
    variant: o = "light-circle",
    size: i = "md",
    color: c = "gray",
    className: s,
    iconVariant: n
  } = e, a = x(
    `variant-${o}`,
    `color-${c}`,
    `size-${i}`,
    s
  ), h = u[i], t = m[i], l = t / 2, g = l - 3 * h / 4 + 1;
  return /* @__PURE__ */ r.jsxs($, { className: a, children: [
    o === "light-circle-outline" && /* @__PURE__ */ r.jsx("svg", { xmlns: "http://www.w3.org/2000/svg", width: t, height: t, viewBox: `0 0 ${t} ${t}`, fill: "none", children: /* @__PURE__ */ r.jsx("circle", { cx: l, cy: l, r: g, stroke: "black", strokeWidth: 1.66667, opacity: "0.3" }) }),
    /* @__PURE__ */ r.jsx(d, { variant: n })
  ] });
};
export {
  k as F
};
